import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CollectionBlock() {
  const collectionData = [
    {
        collectionName: "My Demons",
        collectionTitle: "My Demons",
        collectionDescription: "Our My Demons Collections  is a collection that our shop is proud to present. This is a collection of accessories that have a luxurious beauty. decorated with diamonds that makes it look comfortable to the person who wears. Our My Demons Collections got lots of love from you, so we made lots of them for you. Taste the proverbial Discover Now.",
        collectionLink : "xyz",
        collectionImage : "https://s3-alpha-sig.figma.com/img/1cbf/9185/9b0ff125a52716088749a1b8e89034cd?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AiZuJ79ZDGG-dXpZqYQvDh8uzrIlYd04DsH5D3Xx1JuLIM1jDUcBNCE2rHXOBTOsA0gCEClhUCTcHr4XIeX1nbZPrSckgNDCw~n3GQt2WIxZ28lq746ajSm0uUbOFYb0yWVKg8FP7gy0x7Ps55CQB-CwxT0WUpmyF5Wh32HRhWhb1S9oxbKfPAzZHe9c4LRMzzFfXT1N0lGiE0rP~QNQs4Gh0v0aGbXat4z69rmMlaKzuaIaycyAknIYl0NO6qVh0gYI6ZMXFpcLUyCexz52orW929SnKrYcTVPY3-TUd4wns9aPiNiRcMVNuwI1b4BW-8TSc1Pvbt0d7ScH~RgHdA__"
    },
    {
      collectionName: "Meet me in forget me not",
      collectionTitle: "New Luxury",
      collectionDescription: "New designs for the new collections “Forget me not” inspired by nostalgia daydreams and our never forget and never-ending love for mix of unique materials and luxury of design and color. Now ready to sparkle with you Discover now.",
      collectionLink : "xyz",
      collectionImage : "https://s3-alpha-sig.figma.com/img/14d9/daef/4ccd091e6c580a91672fcef95ccd7113?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KelJa4sCyq7n3VVj-MzOgxuK7isbxTAcGSpR9~vL3HtYfWn0eynrPBG855FP-2kWQViI~6IVIBbr~20TaXjd1BLsNktdbPL-gq5GyC4zwfbUHM4Olk3p-6IhGaLwIyTA9jS9sRNddrtYGMpoel46Bsr7q5lNV8OIQtfI35mGj~K55E6zHpgHnj~BSnZ97JenWbLqDD-sVA5unwAeNpv6txPxl6YULur35gkIP0aKiYbii9Pdb01huHuVfIpU25rkR4Vfq378iJitgLv4TLGjNtai0dCVYRwMNbHeiyN2SxcKBMCyFLxKKagOxQd3xyp9ZY6fsJ6LvodqrV-peZhdBg__"
  }
]

const {t} = useTranslation()
  return (
    <div className='collectionblock'>
      <div className='collectionblock-inner'>
        {collectionData.length ? collectionData.map((result, key)=>{
          return(
            <div className={`collectionblock-inner-block ${key%2 == 0 ? "left" : "right"}`}>
              <div className='collectionblock-inner-block-imagesection'>
                <img src={result?.collectionImage}/>
                <span className='collectionblock-inner-block-imagesection-titletag'>{result?.collectionTitle}</span>
              </div>
              <div className='collectionblock-inner-block-container'>
                <div className='collectionblock-inner-block-container-content'>
                  <div className='collectionblock-inner-block-container-content-header'>{result?.collectionName}</div>
                  <div className='collectionblock-inner-block-container-content-description'>
                    {result?.collectionDescription}
                  </div>
                  <button>{t("Discover")}</button>
                </div>
              </div>
            </div>
          )
        }) : null}
        <div></div>
      </div>
    </div>
  )
}

