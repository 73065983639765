import React, { useRef, useState, useEffect, useCallback } from "react"
import { Link } from 'react-router-dom';
import { _Api } from '../api/_call';
import TableColumnModal from "../common/modules/tableColumn";
import ConfirmationModel from "../common/modules/confirmationmodel";
import { Tablehead } from '../common/modules/Tablehead';
import DateTime from '../common/DateTime';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import { inventorytableHead } from '../common/Tablehead';
import 'datatables.net-fixedcolumns';
import "rc-slider/assets/index.css";
import ProductModal from "../common/modules/ProductModal";
import ProductImageSliderModal from "../common/modules/productImageslider";
import { Pagination } from "../common/Pagination";
import Filtermodal from "../common/modules/Filtermodal";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { useSelector } from 'react-redux'
import TableLoader from "../../admin/common/TableLoader";
import BarcodeModal from "../common/modules/BarcodeModal"
import { selectedInventoryItemsState,countSelectedInventoryItemState } from "admin/setup/recoilStateSelector/PrintPreviewState";
import { pageStatusState, pageHeadingBreadCrumbState } from "admin/setup/recoilStateSelector/profilePageState.js";
import { useRecoilState } from "recoil"


const InventorySelectToPrintout = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const [inventorylist, setInventorylist] = useState([]);
  const [inventorycounts, setInventorycounts] = useState();
  const [filterdata, setFilterdata] = useState()
  const [showoption, setShowoption] = useState(false);
  const [showpos, setShowpos] = useState([]);
  const [transferlist, setTransferlist] = useState([]);
  const [locationidlist, setLocationidlist] = useState([]);
  const [showtransfer, setShowtransfer] = useState(false);
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [newcolumndata, setnewcolumndata] = useState(inventorytableHead);
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [Selectlist, setSelectlist] = useState([]);
  const [getoneproduct, setOneproduct] = useState();
  const [imageid, setImageid] = useState();
  const [socialurl, setSocialurl] = useState();
  const [shareloader, setShareloader] = useState(false);
  const [selectedlocation, setSelectedlocation] = useState();
  const [selectedwarehouse, setSelectedwarehouse] = useState('');
  const [product_id, setProduct_id] = useState()
  const [cart_id, setCart_id] = useState()
  const [getSkipNumbers, setSkipNumbers] = useState(0)
  const [datalodaer, setDatsLodaer] = useState(false)
  const [datatotal, setDatatotal] = useState({ amount: 0, qty: 0 })
  const [payload, setPayload] = useState()
  const [selectedInventoryItems,setSelectedInventoryItems] = useRecoilState(selectedInventoryItemsState)
  let [pageStatus, setPageStatus] = useRecoilState(pageStatusState)
  let [PageHeadingBreadCrumb, setPageHeadingBreadCrumb] = useRecoilState(pageHeadingBreadCrumbState)
  let [countSelectedInventoryItem,setCountSelectedInventoryItem] = useRecoilState(countSelectedInventoryItemState)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const getshareinventary = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "item": payload?.item ? payload?.item : [],
        "collection": payload?.collection ? payload?.collection : [],
        "metal": payload?.metal ? payload?.metal : [],
        "size": payload?.size ? payload?.size : [],
        "stone": payload?.stone ? payload?.stone : [],
        "productStatus": payload?.status ? payload?.status : [],
        "location": payload?.location ? payload?.location : [],
        "price": payload?.price ? payload?.price : [],
        "search": filtername,
      };
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/myInventory`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const getprintmyinventary = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "item": payload?.item ? payload?.item : [],
        "collection": payload?.collection ? payload?.collection : [],
        "metal": payload?.metal ? payload?.metal : [],
        "size": payload?.size ? payload?.size : [],
        "stone": payload?.stone ? payload?.stone : [],
        "productStatus": payload?.productStatus ? payload?.productStatus : [],
        "location": payload?.location ? payload?.location : [],
        "price": payload?.price ? payload?.price : [],
        "search": filtername,
      };
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/myInventory`);
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));
        }, 50)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }
  }

  const handleChange = (event) => {
    if (event.target.checked) {

      let posorderarray = [];
      const selected = [...Selectlist];
      const mainarray = [...inventorylist];
      const transferarray = [...transferlist];
      const locationarray = [...locationidlist];
      let checking = document.querySelectorAll(".inventorytablecheck");
      for (let i = 0; i < checking.length; i++) {
        if (checking[i].checked) {
          posorderarray.push(i);
          setShowoption(true);
        }
        setShowpos(posorderarray);
      }
      if (selected.filter(e => e === event.target.id).length === 0) {
        selected.push(event.target.id);
      }


      setSelectlist(selected);

      const currentid = mainarray.filter(all => {
        return all.id === event.target.id;
      });
      setSelectedInventoryItems((prev)=>{
        console.log(prev,'prev')
        let obj;
        if(prev.length>0){
           obj = [...prev,...currentid]
        }else{
          obj = currentid
        }
        
        return obj;

      })
      setSelectedlocation(currentid[0]?.location_id);
      setSelectedwarehouse(currentid[0]?.warehouse_id ? currentid[0]?.warehouse_id : 'null');
      if (transferarray?.length === 0) {
        setShowtransfer(true);
        locationarray.push(currentid[0]?.location_id);
        setLocationidlist(locationarray);
        transferarray.push(event.target.id);
        setTransferlist(transferarray)
      } else {
        const checkloc = locationarray.filter(locid => locid === currentid[0]?.location_id);
        if (checkloc?.length) {
          transferarray.push(event.target.id);
          setShowtransfer(true);
          setTransferlist(transferarray);
        } else {
          setShowtransfer(false);
        }
      }
      console.log(currentid,'currentid')
   
      console.log(selectedInventoryItems)
    } else {
      let array = [];
      let posorderarray = [];
      const mainarray = [...inventorylist];
      const transferarray = [...transferlist];
      const locationarray = [...locationidlist];
      let checking = document.querySelectorAll(".inventorytablecheck");
      for (let i = 0; i < checking.length; i++) {
        if (checking[i].checked) {
          array.push(i);
          posorderarray.push(i);
          setShowoption(true);
        }
        setShowpos(posorderarray);
      }
      setSelectlist((current) =>
        current.filter((item) => item !== event.target.id)
      );
      if (array.length === 0) {
        setShowoption(false);
      }

      const currentid = mainarray.filter(all => {
        return all.id === event.target.id;
      });
      if (transferarray?.length === 1) {
        setLocationidlist((current) =>
          current.filter((item) => item !== currentid[0]?.location_id)
        );
        setTransferlist((current) =>
          current.filter((item) => item !== event.target.id)
        );
        setShowtransfer(true);
        setSelectedlocation('');
        setSelectedwarehouse('');
      } else {
        const checkloc = locationarray.filter(locid => locid === currentid[0]?.location_id);
        if (checkloc?.length) {
          setTransferlist((current) =>
            current.filter((item) => item !== event.target.id)
          );
          var callcheck = document.querySelectorAll(".inventorytablecheck");
          for (var i = 0; i < callcheck.length; i++) {
            if (callcheck[i].checked) {
              let locationid = callcheck[i].getAttribute('data-locationid');
              const notmatch = locationarray.filter(locid => locid !== locationid)
              if (notmatch.length) {
                setShowtransfer(false);
              } else {
                setShowtransfer(true);
              }
            }
          }
        } else {
          if (transferarray?.length) {
            setShowtransfer(true);
          } else {
            setShowtransfer(false);
          }
        }
      }

    }

  }


  const inventorycountsapi = async (filter, search) => {
    try {
      const postdata = {
        "location": filter?.location ? filter?.location : [],

      };
      let res = await _Api(postdata, `api/v1/Inventory/my/inventory-statics`)
      if (res?.code === 200) {
        setInventorycounts(res?.data);
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const inventorylowercount = async (filter, search, limit) => {
    try {
      const postdata = {
        "item": filter?.item ? filter?.item : [],
        "collection": filter?.collection ? filter?.collection : [],
        "metal": filter?.metal ? filter?.metal : [],
        "size": filter?.size ? filter?.size : [],
        "stone": filter?.stone ? filter?.stone : [],
        "productStatus": filter?.status ? filter?.status : [],
        "location": filter?.location ? filter?.location : [],
        "price": filter?.price ? filter?.price : [],
        "search": search === "" ? search : filtername,
        "limit": limit,
      };
      let res = await _Api(postdata, `api/v1/Inventory/my/inventory-lowerStatics`)
      if (res?.code === 200) {
        setDatatotal(res?.data);
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const filterbyname = (search) => {
    setstate({ ...state, currentPage: 1 });
    Getmyinventory(1, limit, filterdata, search, 0);
  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getmyinventory(1, limit, filterdata);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getmyinventory(current, "", filterdata);

  };

  const Getmyinventory = useCallback(async (current, firstlimit, filter, search, skip) => {
    let tablelimit = 1;
    if (firstlimit === "ALL") {
      tablelimit = 1
    } else {
      tablelimit = firstlimit ? firstlimit : limit
    }
    setDatsLodaer(true)
    var skipNumber = current ? (current - 1) * tablelimit : 0;
    if (skip === 0 || skipNumber === 0) {
      inventorycountsapi(filter, search)
    }
    try {

      const postdata = {
        "item": filter?.item ? filter?.item : [],
        "collection": filter?.collection ? filter?.collection : [],
        "metal": filter?.metal ? filter?.metal : [],
        "size": filter?.size ? filter?.size : [],
        "stone": filter?.stone ? filter?.stone : [],
        "productStatus": filter?.status ? filter?.status : [],
        "location": filter?.location ? filter?.location : [],
        "price": filter?.price ? filter?.price : [],
        "search": search === "" ? search : filtername,
        "limit": firstlimit ? firstlimit : limit,
        "skip": skip === 0 ? skip : skipNumber,
      };
      setPayload(postdata)
      UnSetdataTable("my_inventory");
      let resPoinventory = await _Api(postdata, `api/v1/Inventory/my/list/search`);
      if (resPoinventory?.code === 200) {
        setFilterdata(filter)
        setSelectAllStatus(false)
        setDatsLodaer(false)
        setSkipNumbers(skip === 0 ? skip : skipNumber);
        SetdataTable('my_inventory', "7", newcolumndata);
        let pageini = 1;
        if (postdata.limit === "ALL") {
          pageini = resPoinventory?.total
        } else {
          pageini = postdata.limit
        }
        setstate({
          ...state,
          totalPages: Math.ceil(resPoinventory?.total / pageini),
          currentPage: current ? current : 1
        });

        setInventorylist(resPoinventory?.data);
        inventorylowercount(filter, search, (parseInt(postdata?.limit) + parseInt(postdata?.skip)))

      } else {
        SetdataTable('my_inventory', "7", newcolumndata);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
    // eslint-disable-next-line
  }, [filtername, limit, state]);

  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    Getmyinventory();
    // eslint-disable-next-line
  }, [Getmyinventory]);


  useEffect(() => {
    if (SelectAllStatus) {
      let callcheck = document.querySelectorAll(".inventorytablecheck");
      const selected = [...Selectlist];
      const transferarray = [...transferlist];
      let location_id = []
      let warehouse_id = []
      for (let i = 0; i < callcheck.length; i++) {
        let status = callcheck[i].getAttribute('data-status');
        if (status === 'reserve' || status === 'transit') {
          callcheck[i].checked = false;
        }
        if (callcheck[i].checked) {
          let id = callcheck[i].getAttribute('data-id');
          let location = callcheck[i].getAttribute('data-locationid')
          let warehouse = callcheck[i].getAttribute('warehouse_id')
          location_id.push(location)
          warehouse_id.push(warehouse)
          if (selected.indexOf(id) === -1) {
            selected.push(id);
          }
          const array = Array.from(new Set(inventorylist.map((item) => item.location_id)));
          if (array?.length === 1) {
            setShowtransfer(true);
            transferarray.push(id);
          }
        }
      }
      setSelectedlocation(location_id[0])
      if (warehouse_id[0]) {
        setSelectedwarehouse(warehouse_id[0])
      } else {
        setSelectedwarehouse('null')
      }
      setTransferlist(transferarray);
      setSelectlist(selected);
    } else {
      let callcheck = document.querySelectorAll(".inventorytablecheck");
      let newSelectlist = [...Selectlist];
      let newTransferlist = [...transferlist];
      for (let i = 0; i < callcheck.length; i++) {
        let id = callcheck[i].getAttribute('data-id');
        newSelectlist = newSelectlist.filter((item) => item !== id);
        newTransferlist = newTransferlist.filter((item) => item !== id);
      }
      setSelectlist(newSelectlist);
      setTransferlist(newTransferlist);
    }
    // eslint-disable-next-line
  }, [SelectAllStatus, inventorylist]);


  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_my_inventory',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    callcolumns();
  }, []);

  useEffect(() => {
  
  }, [selectedInventoryItems]);



  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        "item": payload?.item ? payload?.item : [],
        "collection": payload?.collection ? payload?.collection : [],
        "metal": payload?.metal ? payload?.metal : [],
        "size": payload?.size ? payload?.size : [],
        "stone": payload?.stone ? payload?.stone : [],
        "productStatus": payload?.productStatus ? payload?.productStatus : [],
        "location": payload?.location ? payload?.location : [],
        "price": payload?.price ? payload?.price : [],
        "search": filtername,
      }
      let res = await _Api(postdata, `api/v1/Inventory/my/inventoryExport`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res?.message), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const downloadexclpricesheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/Inventory/my/inventoryPriceExport`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res?.message), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const deallcocation = async (event) => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "po_product_id": product_id,
        "customOrderId": cart_id,
        "type": event
      }
      let res = await _Api(postdata, `api/v1/Inventory/po/deallocation`);
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("Deallocation susses!!"));
        Getmyinventory()
      } else {
        Updateloading(t, GetloadingID, t("somthing went wrong"), 'error');
        Getmyinventory()
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  return (

    <React.Fragment>
      <div className="main-body my-inventory-page inventory_select_printout_page" >
       <button className="confirm_add_to_print" onClick={()=>{
              setCountSelectedInventoryItem(selectedInventoryItems.length)

        setPageStatus("print_preview_page")
        
        setPageHeadingBreadCrumb({
            heading: "Barcode Printing",
            breadCrumbName: "Barcode Printing",

        })
       
       }}>
        Confirm
       </button>
        <div className="container">
          <div className="row inventory_select_printout_row">
            <div className="col-lg-12 inventory_select_printout_content">
          
         
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="search_bar_block">
                <div className="main-body-top-status-bar-filter">
                    <button className="main-body-top-status-bar-filter-icon" data-bs-toggle="modal" data-bs-target="#filterinventory">
                      <img className='d-block' src={base_assets + "/images/icons/search-outline.png"} alt="" />
                    </button>
                    <input
                      type="text"
                      value={filtername}
                      className="main-body-top-status-bar-filter-input"
                      placeholder={t("Stock_ID / SKU")}
                      onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                      onChange={(e) => { setFiltername(e.target.value) }}
                    />
                    {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                    <button onClick={filterbyname} className="main-body-top-status-bar-filter-search">
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>


                </div>
          
                <div className="main-body-top-status-bar-multi-date-picker filter_date_search_select_inventory">
            
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                <table
                  id="my_inventory"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={inventorytableHead} tblName={'tbl_my_inventory'} setfilterby={""} setShowoption={setSelectAllStatus} />
                  <tbody>
                    <>

                      {inventorylist?.length ? inventorylist.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td>
                              <div className={result?.status_type === "reserve" || result?.status_type === "transit" ? 'com-check checkbox-disabled' : 'com-check'}>
                                <input key={result?.id}
                                  className='inventorytablecheck inventorytableHead gisClassTh'
                                  id={result?.id}
                                  data-id={result?.id}
                                  data-locationid={result?.location_id}
                                  warehouse_id={result?.warehouse_id}
                                  value={result?.id}
                                  data-status={result?.status_type}
                                  type="checkbox"
                                  onChange={(e) => handleChange(e)}
                                />
                                <label htmlFor={result?.id} className="com-check-label" />
                              </div>
                            </td>
                            <td className="fixed-side"> {parseInt(key + getSkipNumbers + 1)}</td>
                            <td className="table-pro-img" onClick={() => setImageid(result?.product_id)} data-bs-toggle="modal" data-bs-target="#ProductViewModal">
                              <img src={result?.main_image ? result?.main_image : base_assets + "images/icon/camera_profile.png"} alt="" />
                            </td>
                            <td className="col-highlight link-color cursor-pointer"
                              onClick={() => {
                                setOneproduct(result);
                              }}
                              data-bs-toggle="modal" data-bs-target="#ProductInfoModal">
                              {result?.SKU ? result?.SKU : ""}
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.item_code ? result?.item_code : ""}<span className="tooltip-area-up-text">{result?.item_name ? result?.item_name : ""}</span></div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.collection_code ? result?.collection_code : ""}<span className="tooltip-area-up-text">{result?.collection_name ? result?.collection_name : ""}</span></div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.metal_code ? result?.metal_code : ""}<span className="tooltip-area-up-text">{result?.metal_name ? result?.metal_name : ""}</span></div>
                            </td>
                            <td className="col-highlight">
                              <div className="tooltip-area-up">{result?.stone_code ? result?.stone_code : ""}<span className="tooltip-area-up-text">{result?.stone_name ? result?.stone_name : ""}</span></div>
                            </td>
                            <td className="col-highlight">{result?.size_name ? result?.size_name : ""}</td>
                            {
                              result?.status_stock_id === "stock" ?
                                <td className="light-blue-text col-highlight">
                                  <div className='tooltip-area-up'>
                                    {result?.stock_id ? result?.stock_id : ""}
                                    <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                  </div>
                                </td> :
                                result?.status_stock_id === "transit" ?
                                  <td className="status-green-text col-highlight">
                                    <div className='tooltip-area-up'>
                                      {result?.stock_id ? result?.stock_id : ""}
                                      <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                    </div>
                                  </td> :
                                  result?.status_stock_id === "warehouse" ?
                                    <td className="status-gray-text col-highlight">
                                      <div className='tooltip-area-up'>
                                        {result?.stock_id ? result?.stock_id : ""}
                                        <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                      </div>
                                    </td> :
                                    result?.status_stock_id === "reserve" ?
                                      <td className="yellow-text col-highlight">
                                        <div className='tooltip-area-up'>
                                          {result?.stock_id ? result?.stock_id : ""}
                                          <span className="tooltip-area-up-text">{result?.customer_name} {result?.customer_name ? <br /> : ''}  {result?.delivery_date}</span>
                                        </div>
                                      </td> :
                                      result?.status_stock_id === "repair_order" ?
                                        <td className="purple-text col-highlight">
                                          <div className='tooltip-area-up'>
                                            {result?.stock_id ? result?.stock_id : ""}
                                            <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                          </div>
                                        </td> :
                                        result?.status_stock_id === "custom_order" ?
                                          <td className="danger-text col-highlight">
                                            <div className='tooltip-area-up'>
                                              {result?.stock_id ? result?.stock_id : ""}
                                              <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                            </div>
                                          </td> : ""
                            }
                            <td className='sku-col table_btn_expand_col col-highlight'
                              onClick={() => {
                                setProduct_id(result?.id); setCart_id(result?.pos?.cart_id)
                              }}>
                              {result?.allocatable === true ?
                                <div className="td-icons-wrap m-3 d-inline-block">
                                  <div className="td-icons-wrap-link">
                                    <img
                                      className="img"
                                      src={
                                        base_assets +
                                        'images/icons/link-icon.svg'
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div> : ""}
                              <div className="td-icons-wrap-user d-inline-block">
                                {result?.pos_Qty !== 0 && result?.pos?.order_type === "repair_order" ?
                                  <div className='tooltip-area-up'>
                                    <div className="td-icons-wrap-user d-inline-block"><img
                                      className="img"
                                      src={
                                        base_assets +
                                        'images/icons/user-purple.png'
                                      }
                                      alt=""
                                    />
                                    </div>
                                    <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                  </div> : result?.pos_Qty !== 0 ?
                                    <div className='tooltip-area-up' data-bs-toggle="modal" data-bs-target="#allocation-listedModal">
                                      <div className="td-icons-wrap-user d-inline-block"><img
                                        className="img"
                                        src={
                                          base_assets +
                                          'images/icons/user-red.png'
                                        }
                                        alt=""
                                      />
                                      </div>
                                      <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                    </div> : ""}
                              </div>
                              <div className="td-icons-wrap-user d-inline-block">
                                <div className='tooltip-area-up'>
                                  {result?.status_type === "  " ?
                                    <div className="td-icons-wrap-user d-inline-block"><img
                                      className="img"
                                      src={
                                        base_assets +
                                        'images/icons/user-yellow.png'
                                      }
                                      alt=""
                                    />
                                    </div> : ""}
                                  <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}<br />{result?.delivery_date}</span>
                                </div>
                              </div>
                            </td>
                            <td className="col-highlight">{result?.po_QTY ? result?.po_QTY : 0}</td>
                            <td className="col-highlight">{result?.price ? result?.price : ""}</td>
                            <td className="col-highlight">{result?.aging ? result?.aging : "0"}</td>
                            <td className='location-td col-highlight'> <span className='link-color'>  {result?.location_name ? result?.location_name : ""} </span>   <span className='text-center location-td-sub'>{result?.warehouse_location_name ? result?.warehouse_location_name : ''}</span>   </td>
                            <td className='col-highlight'>
                              {
                                result?.status_type === "stock" ?
                                  <div className="status-dot light-blue-bg  tooltip-area-up"><span className='tooltip-area-up-text'>{t("Stock")}</span></div> :
                                  result?.status_type === "transit" ?
                                    <div className="status-dot green-bg  tooltip-area-up"><span className='tooltip-area-up-text'>{t("Transit")}</span></div> :
                                    result?.status_type === "warehouse" ?
                                      <div className="status-dot status-gray-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Warehouse")}</span></div> :
                                      result?.status_type === "reserve" ?
                                        <div className="status-dot yellow-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Reserve")}</span></div> :
                                        result?.status_type === "repair_order" ?
                                          <div className="status-dot purple-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Repair Order")}</span></div> :
                                          <div className="status-dot danger-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Custom Order")}</span></div>
                              }
                            </td>
                            <td className="text-danger col-highlight">{result?.POS_Order ? result?.POS_Order : ""}</td>
                            <td className="text-danger col-highlight">{result?.customer_name ? result?.customer_name : ""}</td>
                            <td className="text-danger col-highlight">{result?.delivery_date ? result?.delivery_date : ""}</td>
                            <td className="text-danger col-highlight">{result?.due_days ? result?.due_days : ""}</td>
                            <td className="col-highlight">{result?.remark ? result?.remark : "-"}</td>
                          </tr>
                        );
                      }) : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>{datatotal?.qty ? datatotal?.qty : 0}</td>
                      <td>
                        {datatotal?.amount ? datatotal?.amount : ""}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer && <TableLoader />}
              </div>
            </div>
            {inventorylist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <BarcodeModal selectList={Selectlist} inventoryList={inventorylist} />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={''} />
        <ProductImageSliderModal
          main_id={imageid}
        ></ProductImageSliderModal>
        <Filtermodal submitfiltter={Getmyinventory} limit={limit} custom_filter_enable={true} />
        <div className="modal fade common-modal common-modal-danger" id="allocation-listedModal" tabIndex={-1} aria-labelledby="allocation-listedModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img className="common-modal-exclamation-img" src={base_assets + "images/icons/exclamation-mark-red.png"} alt="" />
                <p className="modal-content-text">{t("Are you sure you want to Free this item ?")}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn modal-content-no" data-bs-dismiss="modal" onClick={(e) => deallcocation('NO')}>{t("Order Close")}</button>
                <button type="button" className="btn modal-content-yes" data-bs-dismiss="modal" onClick={(e) => deallcocation('YES')}>{t("Deallocate")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={inventorytableHead} tblName={'tbl_my_inventory'} setnewcolumndata={setnewcolumndata}
        calldatatable={Getmyinventory}></TableColumnModal>

      <ConfirmationModel ></ConfirmationModel>
    </React.Fragment>
  );

}
export default InventorySelectToPrintout;