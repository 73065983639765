import { atom } from "recoil";

export const selectedInventoryItemsState = atom({
    key:"selectedInventoryItemsState",
    default: []
})

export const selectedProfileState = atom({
    key:"selectedProfileState",
    default: false
})

export const showImportStockIdModalState  = atom({
    key : "showImportStockIdModalState",
    default:false
})

export const countSelectedInventoryItemState = atom({
    key : "countSelectedInventoryItemState",
    default :0
})
