import { jsPDF } from "jspdf";
import { getBarcodeBySku, getBarcodeByStockId } from "../helpers/barcodeHelper";

export const template1 = async (barcode, dataList, config = {}, returnType = "save") => {



  console.log(dataList)
  const fontSizeStoneBylength = {
    "2": 5,
    "3": 4,
    "4": 3,
    "5": 3
  }

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [75, 13]
  });

  // console.log(InventoryList,'InventoryList')
  dataList.map((v, k) => {
    console.log(v.stock_id, 'vmap')

    doc.setFont('helvetica', 'bold');

    doc.setFontSize('6');
    doc.text(v.SKU, 31.5, 2.5, 0);

    doc.setFontSize('10');


    if (config.barcode === true) {

      if (config.barcode_display === "stock_id") {
        doc.addImage(getBarcodeByStockId(barcode.data, v.stock_id), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");
      } else if (config.barcode_display === "sku") {
        console.log(barcode.data, 'skuuuu')
        console.log(v.SKU, 'skuuuu')
        doc.addImage(getBarcodeBySku(barcode.data, v.SKU), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");
      } else {
        doc.addImage(getBarcodeByStockId(barcode.data, v.stock_id), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");

      }

    }

    // doc.addFont('ComicSansMS', 'Comic Sans', 'normal','StandardEncoding');

    doc.setFontSize('6');
    doc.setFont('helvetica', 'bold');


    // Remove the currency symbol and commas



    doc.text(v.stock_id, 31.5, 9.8, 0);

    if (config.metal === true) {
      doc.text(v.metal_code, 44.5, 9.8, 0);
    }


    let numericString = parseFloat(v.price.trim().replace(/[฿, ]/g, '')).toFixed(2);


    if (config.price === true) {


   
      if (config.price_display==="with_currency") {
        if (isNaN(numericString)) {
          doc.text(v.price);

        } else {
          doc.text(v.currency + " " + numericString, 31.5, 12, 0);

        }

      } else {
        doc.text(numericString, 31.5, 12, 0);

      }
      


    }
    const nwt = v.NetWt + " G"
    const gwt = v.GrossWt + " G"

    if (config.weight === true) {
      if (config.weight_display === "nwt") {
        doc.text(nwt, 45.5, 12, 0);
        // doc.text( nwt, 47 ,12, 0);

      } else {
        doc.text(gwt, 45.5, 12, 0); //for no currency layout
        // doc.text( gwt, 47 ,12, 0);

      }
    }

console.log(v.Stones_Data,'vv')

    v.Stones_Data.map((v, k, arr) => {
      console.log(v,"Stones_Data")
      if (v.stone_details.code) {
        if (v.stone_details.code.length >= v.stone_shape_details.code.length) {
          doc.setFontSize(fontSizeStoneBylength[v.stone_details.code.length])
        } else {
          doc.setFontSize(fontSizeStoneBylength[v.stone_shape_details.code.length]);
        }
      }
      console.log(v,"CC")
      let space = 2.5;
      // const text = v.stone_code+" "+v.shape_code+" "+" "+v.Pcs+" "+v.Cts;

      if (config.stone_name === true) {
        if (config.stone_group === true){
          doc.text(v.stone_details.code, 55, space * (k + 1), 0);

          doc.text(v.stone_details.code, 60, space * (k + 1), 0);

        }else{
          doc.text(v.stone_details.code, 55, space * (k + 1), 0);

        }

      }else{
                  doc.text(v.stone_group_details.code, 55, space * (k + 1), 0);

      }

      if (config.stone_group === true&&config.stone_name === false) {
              
                        doc.text(v.stone_details.code, 55, space * (k + 1), 0);

        

      }





      if (config.shape === true) {
        doc.text(v.stone_shape_details.code, 60, space * (k + 1),);
      }
      if (config.item_size === true) {
        doc.text(v.stone_size_details.code, 58, space * (k + 1),);
      }


      if (config.stone_pcs === true) {
        doc.text(v.Pcs, 65.5, space * (k + 1));

      }else{
        doc.text(v.Pcs, 65, space * (k + 1));

      }

      if (config.stone_weight === true) {

        doc.text(v.Cts + v.Unit.substring(0, 1).toLowerCase(), 69, space * (k + 1),);

      }
    })

    if (k + 1 < dataList.length) {
      doc.addPage();

    }
  })
  switch (returnType) {
    case "save":
      return doc.save("barcode.pdf");
 
    case "uri":
      return doc.output('datauristring');
   
    case "printout":
      doc.autoPrint({ variant: 'non-conform' })
      return doc.output('dataurlnewwindow');
      // return doc.addJS('print({});');



    default:
      return doc.save("barcode.pdf");
  }

}