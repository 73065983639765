import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SaleContent() {
    const {t} = useTranslation();
  return (
    <div className='saleblock'>
        <div className='saleblock-inner'>
            <div className='saleblock-inner-headings'>
                <span className='saleblock-inner-headings-title'>{t("Winter Sale")}</span>
                <span className='saleblock-inner-headings-subtitle'>{t("50% Off everything for a limited time only")}</span>
            </div>
            <div className='saleblock-inner-timer'>
                <div className='saleblock-inner-timer-section'><span className='saleblock-inner-timer-section-title'>{t("200")}</span><span className='saleblock-inner-timer-section-subtitle'>{t("Days")}</span></div>
                <div className='saleblock-inner-timer-section'><span className='saleblock-inner-timer-section-title'>{t("15")}</span><span className='saleblock-inner-timer-section-subtitle'>{t("Hours")}</span></div>
                <div className='saleblock-inner-timer-section'><span className='saleblock-inner-timer-section-title'>{t("04")}</span><span className='saleblock-inner-timer-section-subtitle'>{t("Minutes")}</span></div>
                <div className='saleblock-inner-timer-section'><span className='saleblock-inner-timer-section-title'>{t("50")}</span><span className='saleblock-inner-timer-section-subtitle'>{t("Seconds")}</span></div>
            </div>
            <div className='saleblock-inner-button'>
                <button>{t("Shop Now")}</button>
            </div>
        </div>
    </div>
  )
}
