import { _Api } from "../../api/_call";
import { template1 } from "../../../components/common/barcodeTemplate/template1.js";
import { template2 } from "../../../components/common/barcodeTemplate/template2.js";


const getSelectedDataList = (selectedList, DataList, dataField = "") => {
  let data = [];
  // console.log(selectedList,'getSelectedDataList selectedList')
  // console.log(DataList,'getSelectedDataList DataList')
  if (selectedList.length > 0) {

    selectedList.map(function (v) {
      console.log(v)
      const filteredDataList = DataList.filter((l, idx) => l.id === v.id);
      if (filteredDataList.length > 0) {
        if (dataField !== "") {
          data.push(filteredDataList[0][dataField])

        } else {
          data.push(filteredDataList[0])

        }
      }
    })
  } else {
    return [];
  }

  return data;
}




export const exportPdf = async (templateProfileId, selectList, dataList, returnType = "save") => {
  let barcode;
  let qrCode;
  console.log(templateProfileId, 'exportPdf')
  console.log(selectList, 'exportPdf')
  console.log(dataList, 'exportPdf')

  const allSelectedDataList = getSelectedDataList(selectList, dataList);
  const stock_id = getSelectedDataList(selectList, dataList, "stock_id");
  const sku = getSelectedDataList(selectList, dataList, "SKU");
  console.log(allSelectedDataList, 'allSelectedDataList')
  const templateProfile = await _Api({ _id: templateProfileId }, "api/v1/template-printout/getByid")

  if (templateProfile.data.profile_setup_info.barcode === true) {

    if (templateProfile.data.profile_setup_info.barcode_display == "stock_id") {

      barcode = await _Api({ barcodedata: stock_id }, "api/v1/barcode/create")

    } else {
      barcode = await _Api({ barcodedata: sku, dataType: "sku" }, "api/v1/barcode/create")

    }
  }
  if (templateProfile.data.profile_setup_info.qr === true) {
    if (templateProfile.data.profile_setup_info.qr_type_display === "offline") {
      qrCode = await _Api({ qrCodeData: stock_id, dataList: allSelectedDataList,templateProfile:templateProfile.data.profile_setup_info }, "api/v1/qr-code/createofflinetext")

    } else if (templateProfile.data.profile_setup_info.qr_type_display === "online") {
      qrCode = await _Api({ url: templateProfile.data.profile_setup_info.qr_url_display }, "api/v1/qr-code/createwebsitelink")

    } else {
      qrCode = await _Api({ qrCodeData: stock_id, dataList: allSelectedDataList,templateProfile:templateProfile.data.profile_setup_info }, "api/v1/qr-code/createofflinetext")
    }
  }



  const { data } = await templateProfile;

  if (data.current_template_layout_selected.value == "template_1") {
    return template1(barcode, allSelectedDataList, data.profile_setup_info, returnType);

  } else if (data.current_template_layout_selected.value == "template_2") {

    return template2(barcode, allSelectedDataList, qrCode, 'offline', data.profile_setup_info, returnType);

  } else {
    return template1(barcode, allSelectedDataList, data.profile_setup_info, returnType);

  }




}