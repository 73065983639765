import React from 'react'
import "./footer.scss"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Messegeboxblue from '../../../admin/common/icons/sidebar/Messegeboxblue';
import DownArrow from '../assets/icons/DownArrow';
import Globe from '../assets/icons/Globe';
const Footer = (props) => {
    const { websiteinfo} = props;
    const { t } = useTranslation();
    return (
        <div className='ecommerce-footer'>
            <div className='ecommerce-footer-inner'>
                <div className='ecommerce-footer-inner-content'>
                    <div className='ecommerce-footer-inner-content-block'>
                        <div className='ecommerce-footer-inner-content-block-logo'>
                        <Link to="/">
                        <img src={websiteinfo?.websiteInfo?.logo} alt="" />
                        </Link>
                        </div>
                    </div>
                    <div className='ecommerce-footer-inner-content-block'>
                        <div className='ecommerce-footer-inner-content-block-title'>{t("Explore")}</div>
                        <div className='ecommerce-footer-inner-content-block-data'>
                            <Link>{t("Categories")}</Link>
                            <Link>{t("Collections")}</Link>
                            <Link>{t("Book an Appointment")}</Link>
                            <Link>{t("Contact us")}</Link>
                        </div>
                    </div>
                    <div className='ecommerce-footer-inner-content-block'>
                    <div className='ecommerce-footer-inner-content-block-title'>{t("Wishlist")}</div>
                        <div className='ecommerce-footer-inner-content-block-data'>
                            <Link>{t("My wishlist")}</Link>
                        </div>
                    </div>
                    <div className='ecommerce-footer-inner-content-block'>
                    <div className='ecommerce-footer-inner-content-block-title'>{t("Account")}</div>
                        <div className='ecommerce-footer-inner-content-block-data'>
                            <Link>{t("My Account")}</Link>
                        </div>
                    </div>
                </div>
                <div className='ecommerce-footer-inner-bottom'>
                    <div className='ecommerce-footer-inner-bottom-policy'><span>{t("Privacy policy")}</span>&bull;<span>{t("Terms of service")}</span></div>
                    <div className='ecommerce-footer-inner-bottom-controls'>
                        <div className='ecommerce-footer-inner-bottom-controls-socials'>
                            <span><Messegeboxblue/></span>
                            <span><Messegeboxblue/></span>
                            <span><Messegeboxblue/></span>
                        </div>
                        <div className='ecommerce-footer-inner-bottom-controls-lang'>
                                <Globe/>
                                <span>{t("English (US)")}</span>
                                <span className='icon-holder'>
                                   <DownArrow height="5" width="10" color="#767676"/>
                                </span>
                        </div>
                        <div className='ecommerce-footer-inner-bottom-controls-country'>
                        <span>{t("United State")}{t("USD $")} </span>
                        <span className='icon-holder'>
                                <DownArrow height="5" width="10" color="#767676"/>    
                        </span>
                    </div>
                    </div>
                    
                </div>
                </div>
                {/* <span>{t("GIS © 2024 Jeweal Prosoft Co., Ltd. All rights reserved.")}</span>
                <ul>
                    <li className='ecommerce-footer-inner-singleList'>
                        <span className='ecommerce-footer-inner-singleList-dotBox'></span>{t("Privacy policy")}</li>
                    <li className='ecommerce-footer-inner-singleList'><span className='ecommerce-footer-inner-singleList-dotBox'></span>{t("Terms of service")}</li>
                </ul> */}
            </div>
    )
}

export default Footer