import { toFormatPrice } from "Ecommerce/common/Formateprice";
import Spinner from "Ecommerce/common/Spinner";
import React, { useEffect, useState, useRef } from "react";
import CartBag from "../../../common/images/cartBag.png"
import DeleteIconGrey from '../../../common/images/deleteicon-grey.png'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _Api } from "Ecommerce/common/api";
import PlusIcon from "Ecommerce/template_three/assets/icons/PlusIcon";
import MinusIcon from "Ecommerce/template_three/assets/icons/MinusIcon";
import CrossIcon from "Ecommerce/template_three/assets/icons/CrossIcon";
import DeleteIcon from "Ecommerce/template_three/assets/icons/DeleteIcon";
import DeleteConfirmationPopup from "../Delete/DeleteConfirmationPopup";

export default function AddCartModal(props) {

    const { t } = useTranslation();
    const modalRef = useRef(null);
    const { isopencart, setisopencart } = props;
    const [cartitems, setCartitems] = useState([]);
    const [checkoutItems, setCheckoutItems] = useState([]);
    const [, setCurrency] = useState();
    const [loading, setLoading] = useState(false)
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const getcartItems = async () => {
      console.log("from add to cart page")
      setLoading(true)
      let inputs = document.querySelectorAll(".itemscheckbox");
      if (document.getElementById("selectallcheck")) {
        document.getElementById("selectallcheck").checked = false;
      }
      if (inputs?.length) {
        for (let i = 0; i < inputs?.length; i++) {
          inputs[i].checked = false;
        }
      }
      setCheckoutItems([])
      let postdata = {}
      const result = localitem?.map(item => ({
        diamond_id: item?.diamonddetails?.id,
        id: item?.id,
        qty: item?.qty,
        temp_cart_id: item?.temp_cart_id
      }));
      try {
        postdata = { "diamond_ids": result }
        console.log(postdata, "here is posdata from add to cart page")
        let res = await _Api(postdata, `order/getCartDetails`)
        if (res?.statusCode === 200) {
          if (res?.data) {
            console.log(res?.data, "our data")
            let array = [...res?.data];
            array?.forEach((result) => {
              if (!result?.diamond) {
                result.qty = parseInt(localitem.filter(e => e?.id === result?.id)[0]['qty']);
              }
            })
            setCartitems(array);
            setCurrency(res?.CURRENCY);
            if (res?.deleted_ids?.length) {
              localitem.forEach((result) => {
                if (!res?.deleted_ids.includes(result?.id)) {
                  let data = []
                  data.push(result)
                  localStorage.setItem('CartItems', JSON.stringify(data));
                }
              })
            }
          }
          setLoading(false)
        }
  
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  
    const changeqty = (label, id) => {
      let array = [...cartitems];
      let updatedLocalItems = [...localitem];
  
      array = array.map(e => {
        if (e.id === id) {
          if (label === 'minus' && e.qty > 1) {
            e.qty -= 1;
          } else if (label !== 'minus') {
            e.qty += 1;
          }
        }
        return e;
      });
  
      updatedLocalItems = updatedLocalItems.map(e => {
        if (e.id === id) {
          if (label === 'minus' && e.qty > 1) {
            e.qty -= 1;
          } else if (label !== 'minus') {
            e.qty += 1;
          }
        }
        return e;
      });
  
      localStorage.setItem('CartItems', JSON.stringify(updatedLocalItems));
      setCartitems(array);
    };
  
    const selectAllcheck = (e) => {
      let inputs = document.querySelectorAll(".itemscheckbox");
      // if (e.target.checked) {
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].checked = true;
        }
        let array = [...cartitems];
        let cartitemsarray = [];
        array?.map((result) => {
          if (result?.diamond) {
            cartitemsarray.push({ id: result?.id, qty: 1, temp_cart_id: result?.temp_cart_id, diamond_id: result?.diamond?.id, price: (parseFloat(result?.UnFormatPrice) + parseFloat(result?.diamond?.UnFormatPrice)) })
          } else {
            cartitemsarray.push({ id: result?.id, qty: result?.qty, price: result?.UnFormatPrice })
          }
          return true;
        });
        setCheckoutItems(cartitemsarray);
      // } 
      // else {
      //   for (let i = 0; i < inputs.length; i++) {
      //     inputs[i].checked = false;
      //   }
      //   setCheckoutItems([]);
      // }
    }
  
    const inputcheck = (e, id, qty, price) => {
      console.log(e, id, qty, price, "input check")
      let checkoutarray = [...checkoutItems];
      if (e.target.checked) {
        checkoutarray.push({ 'id': id, 'qty': qty, 'price': price });
        // if (document.querySelectorAll(".itemscheckbox:checked").length === document.querySelectorAll(".itemscheckbox").length) {
        //   document.getElementById("selectallcheck").checked = true;
        // }
      } else {
        checkoutarray = checkoutarray.filter(e => e?.id !== id);
        // document.getElementById("selectallcheck").checked = false;
      }
      setCheckoutItems(checkoutarray);
  
    }
  
    const deleteItems = () => {
      let checkoutarray = [...checkoutItems];
      checkoutarray.forEach((result) => {
        localitem = localitem.filter(e => e?.id !== result?.id);
      })
      localStorage.setItem('CartItems', JSON.stringify(localitem));
      setCheckoutItems([]);
      getcartItems();
  
    }
  
    useEffect(() => {
      if (localStorage.getItem('CartItems') && isopencart) {
        getcartItems();
      }
      // eslint-disable-next-line
    }, [isopencart])
    const hidemodal = () => {
      if (modalRef.current) {
        modalRef.current.hide();
      }
    }
    useEffect(() => {
      modalRef.current = new window.bootstrap.Modal(document.getElementById('AddToCartModal'));
  
    }, []);
  
  const showDeleteConfirmation = () =>{
    setIsPopupVisible(true);
  }
  const handleConfirm = () => {
    setIsPopupVisible(false);
    deleteItems();
  };
  
  const handleCancel = () => {
    console.log("delete it is")
    setIsPopupVisible(false);
  };

  return (
    <div className="modal right modalonright fade" id="AddToCartModal" role="dialog">
    <div className="modal-dialog modal-dialog-right">
      <div className="modal-content">
        <div className="modal-header modalonright-headerBox lesspadding">

          <button type="button" className="modalonright-headerBox-crossbtn" onClick={() => setisopencart(false)} data-bs-dismiss="modal" aria-label="Close" >
            <CrossIcon width={18} height={18} />
          </button>
          <h4 className="modalonright-headerBox-modalTitle">{t("Shopping Cart")}</h4>
          <span className="modalonright-headerBox-cartBag"><img src={CartBag} alt="" /></span>
        </div>
        {loading ? (<div className="h-100 d-flex justify-content-center align-items-center">

          <Spinner />

        </div>) : (
          <>
            {cartitems?.length ? (<>
              <div className="modal-body">
                <div className="d-block">
                  {/* <div className="modalonright-bodyBox-productCount">{t("My Cart")} ({cartitems?.length} {t("Items")})</div> */}
                  <div className="modalonright-bodyBox-selectAllBox">
                    <div className="com-ecomCheckBox">
                      {/* <input type="checkbox" onChange={(e) => selectAllcheck(e)} className=" com-ecomCheckBox-inputcheck me-2 cursor-pointer" id="selectallcheck" /> */}
                      <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold text-decoration-underline" onClick={(e) => selectAllcheck()} htmlFor="selectallcheck">{t("Select All")}</label>
                    </div>
                    {/* <div className="modalonright-bodyBox-selectAllBox-DeleteBox">
                      {!checkoutItems?.length ?
                        <span className="modalonright-bodyBox-selectAllBox-DeleteBox-iconBox"><img src={DeleteIconGrey} alt="" /></span>
                        : <><span onClick={showDeleteConfirmation} className="modalonright-bodyBox-selectAllBox-DeleteBox-iconBox"><img src={DeleteIcon} alt="" /></span>
                          <span className="deleteSelect-Show">{checkoutItems?.length ? checkoutItems?.length + " " + t("Selected") : ''}</span></>}
                    </div> */}
                  </div>
                </div>
                <div className="modalonright-bodyBox">
                  {/* cart items */}

                  {cartitems.map((result, key) => {
                    return (
                      <>
                        <div key={key} className={`ecom_cartproduct ${result?.diamond ? 'mixandmatch' : ""}`}>
                          <div className="ecom-darkthemed-checkbox">
                            <input type="checkbox" onChange={result?.diamond ? (e) => inputcheck(e, result?.id, 1, (parseFloat(result?.UnFormatPrice) + parseFloat(result?.diamond?.UnFormatPrice))) : (e) => inputcheck(e, result?.id, result?.qty, result?.UnFormatPrice)} className="itemscheckbox me-2 cursor-pointer" id={result?.id} />
                            <label className="ecom-darkthemed-checkbox-label" htmlFor={result?.id}></label>
                          </div>
                          <div className="ecom_cartproduct-imageBox">
                            <span className="product-img-span">
                              <img src={result?.main_image} alt="" />
                            </span>
                          </div>
                          <div className=" ecom_cartproduct-detailsBox">
                            <div className="ecom_cartproduct-detailsBox-firstBox">
                              <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                  state={{ id: result?.motherProduct?._id }}
                                  onClick={() => modalRef.current.hide()} className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.name}</Link>
                                <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.price}</span>
                              </p>
                              <div className="ecom_cartproduct-detailsBox-firstBox-instock">{t("In Stock")}</div>
                                                            <div className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper">
                                                                { (result?.Metal === '18KBK' || result?.Metal.includes('BK')) && <div className={`black`}></div>}
                                                                { (result?.Metal === '18KWG' || result?.Metal.includes('WG')) && <><div className={`titanium`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Sliver")}</span></>}
                                                                { (result?.Metal === '18KYG' || result?.Metal.includes('YG')) && <><div className={`sunshine`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Yellow Gold")}</span></>}
                                                                { (result?.Metal === '18KRG' || result?.Metal.includes('RG')) && <div className={`peach`}></div>}
                                                            </div>
                              <div className="ecom_cartproduct-detailsBox-firstBox-content">
                              <p className="contentPara">{t("Size")}: {result?.size}</p>
                              <div className="ecom_cartproduct-detailsBox-firstBox-price">{result?.price}</div>
                              </div>
                            </div>
                            {result?.diamond ?

                            <div key={key} className=" ecom_cartproduct diamonditem">
                              <div className="com-ecomCheckBox">

                              </div>
                              <div className="ecom_cartproduct-imageBox">
                                <span className="product-img-span">
                                  <img src={result?.diamond?.main_image} alt="" />
                                </span>
                              </div>
                              <div className=" ecom_cartproduct-detailsBox">
                                <div className="ecom_cartproduct-detailsBox-firstBox">
                                  <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                    <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                      state={{ id: result?.motherProduct?._id }}
                                      onClick={() => modalRef.current.hide()} className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.diamond?.name}</Link>
                                    <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.diamond?.price}</span>
                                  </p>
                                  <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                    <p className="contentPara">{result?.diamond?.SKU}</p>
                                  </div>
                                </div>
                                <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                  <div className='setCountShow'>
                                    <button onClick={!result?.diamond ? () => changeqty('minus', result?.id) : null} className='setCountShow-minusIcon'>
                                      <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                    </button>
                                    <span className='setCountShow-count'>{result?.diamond ? 1 : result?.qty}</span>
                                    <button onClick={!result?.diamond ? () => changeqty('plus', result?.id) : null} className='setCountShow-plusIcon'>
                                      <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                    </button>
                                  </div>
                                  <div className='ecom_cartproduct-detailsBox-secondCountBox-priceShow desktop-productPriceShow'>{result?.diamond?.price}</div>
                                </div>
                              </div>
                            </div>
                            : ""}
                            <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                              <div className='setCountShow'>
                                <button onClick={!result?.diamond ? () => changeqty('minus', result?.id) : null} className='setCountShow-minusIcon'>
                                  <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                </button>
                                <span className='setCountShow-count'>{result?.diamond ? 1 : result?.qty}</span>
                                <button onClick={!result?.diamond ? () => changeqty('plus', result?.id) : null} className='setCountShow-plusIcon'>
                                  <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                </button>
                              </div>
                              <div className='ecom_cartproduct-detailsBox-secondCountBox-priceShow desktop-productPriceShow'>
                                <div className="modalonright-bodyBox-selectAllBox-DeleteBox">
                                    <span onClick={ showDeleteConfirmation} className="modalonright-bodyBox-selectAllBox-DeleteBox-iconBox cursor-pointer">
                                      <DeleteIcon color='#DB0004'/>
                                    </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </>
                    )
                  })}
                </div>

              </div>
              <div className="modal-footer modalonright-footerBox">
                <div className="modalonright-footerBox-codebox">
                  <div className="modalonright-footerBox-codebox-title">{t("Code")}</div>
                  <div className="modalonright-footerBox-codebox-wrapper">
                    <input type="text" placeholder="Enter promo or gift code"/>
                    <button className="btnbold roundbtn">{t("Apply")}</button>
                  </div>
                </div>
                <div className="modalonright-footerBox-grandTotalBox">
                  <div className="modalonright-footerBox-grandTotalBox-total">
                    <span>{t("Sub Total")}</span>
                    <span>
                      {toFormatPrice(checkoutItems?.length ? checkoutItems?.reduce(
                        (a, v) => (a = a + (parseInt(v.qty) * (v.price))),
                        0
                      ) : cartitems?.reduce(
                        (a, v) => (a = a + (parseInt(v.qty) * (v.UnFormatPrice))),
                        0
                      ), { addSymbol: true })}
                    </span>
                  </div>
                  <div className="modalonright-footerBox-grandTotalBox-subtotal">{t("Shipping, taxes, and discounts calculated at checkout")}</div>
                </div>
                <div className="modalonright-footerBox-bottom">
                {checkoutItems?.length ?
                  <Link to="/shipping" state={{ checkoutItems: checkoutItems }} className="btnbold text-decoration-none" onClick={() => hidemodal()}>
                    {t("Proceed to Checkout")}
                  </Link> :
                  <button disabled={checkoutItems?.length ? false : true} className='btnbold disablebtn'>
                    {t("Proceed to Checkout")}
                  </button>}
                  <Link to="/Collection" className="modalonright-footerBox-bottom-continue" onClick={() => setisopencart(false)} data-bs-dismiss="modal" aria-label="Close">{t("Continue Shopping")}</Link>
                  </div>
                  {/* {checkoutItems?.length ?
                  <Link to="/appointment" state={{ checkoutItems: checkoutItems }}
                    className="w-100 text-center text-decoration-none"
                    onClick={() => modalRef.current.hide()}>
                    <button disabled={checkoutItems?.length ? false : true} className='com-ecomCancelBtn w-100 bg-white'>{t("Appointment")}</button>
                  </Link> :
                  <button disabled={checkoutItems?.length ? false : true} className=' com-DisableecomSendBtn w-100'>{t("Appointment")}</button>} */}
                 
              </div>
            </>)
              : (<div className="empltycartBoc">
                <h1 className="empltycartBoc-head" >{t("Your Shopping Cart is empty")}</h1>
                <p className="empltycartBoc-para">{("Still don't have something you want? Try checking out the new products in case you have something you like to add to your cart")}</p>
                <Link to="/" onClick={() => hidemodal()} className="com-ecomCancelBtn text-decoration-none">{t("Continue Shopping")}</Link>
              </div>)
            }

          </>
        )}

      </div>
    </div>
    <DeleteConfirmationPopup
      isVisible={isPopupVisible}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      message="Remove Items"
    />
  </div>
  )
}
